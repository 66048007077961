import React from "react";

const PageLoading = () => {
  return (
    <div style={{
      display: "flex",
      minHeight: "100vh",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "100vw",
    }}>
      <div>
        <h3>Loading...</h3>
      </div>
    </div>
  );
};

export default PageLoading;
