import React from "react";

const More = () => {
  return (
    <div className="page">
      <h2 className="pageTitle">Welcome to More</h2>
    </div>
  );
};

export default More;
