import React from "react";

const Bookmarks = () => {
  return (
    <div className="page">
      <h2 className="pageTitle">Welcome to Bookmarks</h2>
    </div>
  );
};

export default Bookmarks;
